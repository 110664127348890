import React, { useEffect, useState } from 'react';
import "./desktopstyle.css";
const StageResultTable = ({
    selectedCompetition,
    selectedEventID,
    selectedCompetitionID,
    selectedGroupID,
    selectedStageID,
    selectedClassificationID
}) => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [colorMapping, setColorMapping] = useState({});
    
    const aligne = {
      // Horizontally align to center
      alignItems: 'center',     // Vertically align to center
    };
   
    const guestTextColors = [
      '#63AA57', '#6EB9E3', '#758AF8', '#8DEACE',
      '#B6F875', '#A2DDEA', '#9988FF', '#BECEF6', '#BCEAA0',
      '#ECA3A3', '#F5FF88', '#F875D3', '#FFBEA2', '#FFE5A2'
    ];
    // Function to create a color mapping for InGroup values
    const createColorMapping = (data) => {
      const colorMapping = {};
      let colorIndex = 0;
      data.forEach(item => {
        const inGroup = item.InGroup.trim();
        if (!colorMapping[inGroup]) {
          colorMapping[inGroup] = guestTextColors[colorIndex % guestTextColors.length];
          colorIndex++;
        }
      });
      return colorMapping;
    };


    useEffect(() => {
      
      const apiUrl = 'https://www.admin.racepulse.pl/api/resultsGetPenaltiesForCompetition';
    
        const getStageData = async () => {
          try {
            setLoading(true); // Set loading to true when starting to fetch data
            setError(null); // Reset the error state
    
            const response = await fetch(apiUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'UYwkNH50yAFoHI1mR62E'
              },
              body: JSON.stringify({
               
                StageID: selectedStageID,
                ClassificationID: selectedClassificationID
              }),
            });
    
            const data = await response.json();
            console.log(data)
            if ( response.status !== 200) { // Check if data.response exists before accessing code property
              setError('Brak Kar');
              setResults([]);
            } else {
              setResults(data);
              const newColorMapping = createColorMapping(data);
              setColorMapping(newColorMapping); // Set the colorMapping state
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            setError('Error fetching data. Please try again.');
          } finally {
            setLoading(false);
          }
        };
    
        getStageData();
      }, [selectedStageID, selectedCompetitionID, selectedGroupID,selectedClassificationID]);

  const formatTime = (timeString) => {
    if (timeString === null) {
      return '--';
    }
      const [hours, minutes, secondsAndMillis] = timeString.split(':');
      const [seconds, milliseconds] = secondsAndMillis.split('.');
    
      let formattedTime = '';
    
      if (parseInt(hours, 10) !== 0) {
        formattedTime += `${parseInt(hours, 10)}:`;
      }
    
      formattedTime += `${parseInt(minutes, 10)}:${parseInt(seconds, 10)}.${milliseconds}`;
    
      return '+' + formattedTime.replace(/^0:/, ''); // Remove leading zero in hours
    };

   

    return (
      
          
      <div className="dskt-table-container" >
                <div className="has-text-centered ">
                    <p className="title is-4"> </p>
                </div>
                {loading ? (
                    <div className="loading-spinner">
                        {/* Spinning arrow will be displayed here */}
                    </div>
                ) : (
                    <>
                        {error ? (
                           
                              
                            <div class="notification has-text-centered mt-1">
                           
                            <strong> {error}</strong>
                          </div>
                        ) : (<div className="desk-table-container" style={aligne}>
              <div className="title">Kary na odcinku</div>
              <div className="tab-container">
                <table>
                  <thead>
                    <tr className="table-header">
                      <th className="table-cell">#</th>
                      <th className="table-cell">Załoga</th>
                      <th className="table-cell">Samochód &amp; Klasa</th>
                      <th className="table-cell">Kara</th>
                      <th className="table-cell">Opis</th>
                   
                    </tr>


                        </thead>
                        <tbody>
                  {results.map((result, index) => (
                    
                      <tr className="table-row" key={index}>
                       
                        <td className="table-cell">
                          <div className='position-badge-text'>
                            <div className="position-badge">{result.PositionNumber}</div>
                          </div></td>
                        <td className="table-cell table-cell-name">
                        {result.DriverName}<br />{result.CODriverName}
                        </td>
                        <td className="table-cell table-cell-car">
                          <div>{result.Car}</div>
                          <div className="guest-badge" style={{ background: colorMapping[result.InGroup.trim()] }}>
                            <div className="guest-badge-text">{result.InGroup}</div>
                          </div>
                        </td>
                        <td className="table-cell table-cell-time">{result.Penalty}
                       
                        </td>
                        <td className="table-cell ">
                          <div> {result.Comments}</div>
                         
                        </td>
                      </tr>
                       ))}
                  </tbody>
                    </table>
                </div></div>
                        )}
                </>
                )}
            </div> 

    );
};

export default StageResultTable;