import React, { useEffect, useState } from 'react';

const ResultAfterStage = ({
    selectedCompetition,
    selectedEventID,
    selectedCompetitionID,
    selectedGroupID,
    selectedStageID,
    selectedClassificationID
}) => {
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [colorMapping, setColorMapping] = useState({});
    
 
    const guestTextColors = [
      '#63AA57', '#6EB9E3', '#758AF8', '#8DEACE',
      '#B6F875', '#A2DDEA', '#9988FF', '#BECEF6', '#BCEAA0',
      '#ECA3A3', '#F5FF88', '#F875D3', '#FFBEA2', '#FFE5A2'
    ];
    // Function to create a color mapping for InGroup values
    const createColorMapping = (data) => {
      const colorMapping = {};
      let colorIndex = 0;
      data.forEach(item => {
        const inGroup = item.InGroup.trim();
        if (!colorMapping[inGroup]) {
          colorMapping[inGroup] = guestTextColors[colorIndex % guestTextColors.length];
          colorIndex++;
        }
      });
      return colorMapping;
    };

    useEffect(() => {
      const handler = setTimeout(() => {
      const apiUrl = 'https://www.admin.racepulse.pl/api/resultsGetAfterStageResults';
    
        const getStageData = async () => {
          try {
            setLoading(true); // Set loading to true when starting to fetch data
            setError(null); // Reset the error state
    
            const response = await fetch(apiUrl, {
              method: 'POST',
              headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'UYwkNH50yAFoHI1mR62E'
          },
              body: JSON.stringify({
                
                StageID: selectedStageID,
                ClassificationID: selectedClassificationID,
                GroupListID:selectedGroupID.join(',')
              }),
            });
    
            const data = await response.json();
            console.log(data)
            if ( response.status !== 200) { // Check if data.response exists before accessing code property
              setError('Brak Wyników.');
              setResults([]);
            } else {
             const cleanedData = data.map(item => ({
              ...item,
              // Replace newline characters in relevant fields
              Car: item.Car.replace(/\n/g, ''),
              // Add other fields if necessary
            }));
            setResults(cleanedData);
            const newColorMapping = createColorMapping(cleanedData);
          setColorMapping(newColorMapping); // Set the colorMapping state
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            setError('Error fetching data. Please try again.');
          } finally {
            setLoading(false);
          }
        };
    
        getStageData();
      }, 300); // Wait for 300ms before making the API call

      return () => {
        clearTimeout(handler);
      };
    }, [selectedStageID, selectedCompetitionID, selectedGroupID,selectedClassificationID]);

  const formatTime = (timeString) => {
    if (timeString === null) {
      return '--';
    }
      const [hours, minutes, secondsAndMillis] = timeString.split(':');
      const [seconds, milliseconds] = secondsAndMillis.split('.');
    
      let formattedTime = '';
    
      if (parseInt(hours, 10) !== 0) {
        formattedTime += `${parseInt(hours, 10)}:`;
      }
    
      formattedTime += `${parseInt(minutes, 10)}:${parseInt(seconds, 10)}.${milliseconds}`;
    
      return '+' + formattedTime.replace(/^0:/, ''); // Remove leading zero in hours
    };


    return (
      
          
            <div className="column is-half afterStage">
    
                <div className="has-text-centered ">
                    <p className="title is-4"> </p>
                </div>
                {loading ? (
                    <div className="loading-spinner">
                        {/* Spinning arrow will be displayed here */}
                    </div>
                ) : (
                    <>
                        {error ? (
                           
                              
                            <div class="notification has-text-centered mt-1">
                           
                            <strong> {error}</strong>
                          </div>
                        ) :  (
                          <div className="desk-table-container">
                            <div className="title">Po odcinku</div>
                            <div className="tab-container">
                              <table>
                                <thead>
                                  <tr className="table-header">
                                    <th className="table-cell">pozycja</th>
                                    <th className="table-cell">numer</th>
                                    <th className="table-cell">załoga</th>
                                    <th className="table-cell">samochód i klasa</th>
                                    <th className="table-cell">czas</th>
                                    <th className="table-cell">różnica</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {results.map((result, index) => (
                                    <tr className="table-row" key={index}>
                                      <td className="table-cell"><div className='table-cell-position'>
                                      {result.RowNumber}
                                      </div></td>
                                      <td className="table-cell">
                                        <div className='position-badge-text'>
                                          <div className="position-badge">{result.PositionNumber}</div>
                                        </div></td>
                                      <td className="table-cell table-cell-name">
                                      {result.DriverName}<br />{result.CODriverName}
                                      </td>
                                      <td className="table-cell table-cell-car">
                                        <div>{result.Car}</div>
                                        <div className="guest-badge"  style={{ background: colorMapping[result.InGroup.trim()] }}>
                                          <div className="guest-badge-text">{result.InGroup}</div>
                                        </div>
                                      </td>
                                      <td className="table-cell table-cell-time">{result.ResultAndPenalty}
                                      {result.Penalty && <div className="penalty">
                          <div className="penalty-text">{`+${result.Penalty}`}</div>
                        </div>}
              
              
                                      </td>
                                      <td className="table-cell ">
                                        <div> {formatTime(result.TimeDifferenceToFirst)}</div>
                                        <div>{formatTime(result.DiffToPrev) }</div>
                                      </td>
                                    </tr>
                                     ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                </>
                )}
            </div> 

    );
};

export default ResultAfterStage;