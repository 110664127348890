import React, { useState, useEffect } from 'react';
import StageResultTable from './StageResultTable';
import ResultAfterStage from './ResultAfterStageTable';
import StageTestResultTable from './StageTestResult';
import Dropdown from './Component/dropdown';

const ResultTable = ({
  selectedCompetition,
  selectedEventID,
  selectedCompetitionID,
  selectedGroupID,
  selectedStageID,
  StageStatusDesc,
  selectedStageIDStatus,
  selectedClassificationID,
  stageIsTested,
  selectedGroupList,
}) => {
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(selectedGroupID);

  useEffect(() => {
    const storedGroupID = localStorage.getItem('groupID');
    
    const a = storedGroupID;
   
    if (storedGroupID !== 'null') {
      setSelectedDropdownOption([storedGroupID]);
    } else {
      setSelectedDropdownOption(selectedGroupID);
    }
   
  }, [selectedGroupID]);

  const handleDropdownChange = (newValue) => {
    setSelectedDropdownOption(newValue);
    localStorage.setItem('groupID', newValue);
  };

  return (
    <div>
      {selectedStageIDStatus ? (
        <div className="section">
          <div className="notification has-text-centered mt-1">
            <p>Odcinek odwołany {StageStatusDesc}</p>
          </div>
        </div>
      ) : stageIsTested ? (
        <StageTestResultTable
          selectedCompetition={selectedCompetition}
          selectedEventID={selectedEventID}
          selectedCompetitionID={selectedCompetitionID}
          selectedGroupID={selectedGroupID}
          selectedStageID={selectedStageID}
          selectedClassificationID={selectedClassificationID}
        />
      ) : (
        <div className="dskt-table-container">
          <Dropdown
            options={selectedGroupList.map((option) => ({
              label: option.name,
              value: option.value,
            }))}
            value={selectedDropdownOption} // Pass the stored value here
            onSelectionChange={handleDropdownChange}
          />
          <div className="columns">
            <>
              <StageResultTable
                selectedCompetition={selectedCompetition}
                selectedEventID={selectedEventID}
                selectedCompetitionID={selectedCompetitionID}
                selectedGroupID={selectedDropdownOption}
                selectedStageID={selectedStageID}
                selectedClassificationID={selectedClassificationID}
              />
              <ResultAfterStage
                selectedCompetition={selectedCompetition}
                selectedEventID={selectedEventID}
                selectedCompetitionID={selectedCompetitionID}
                selectedGroupID={selectedDropdownOption}
                selectedStageID={selectedStageID}
                selectedClassificationID={selectedClassificationID}
              />
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultTable;
