import React, { useState, useEffect } from 'react';
import CompetitionInfoCard from './CompetitionInfoCard';
import ResultTable from './ResultTable';
import FooterPart from './Footer';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar';
import useMobileCheck  from './useMobileCheck';
import Leaderboard from './MobileResultTable';
import TestLeaderboard from './MobileTestResult';

const Results = () => {
  const { EventID } = useParams();
  const isMobile = useMobileCheck();

  // Extract the numeric part from EventID
  const numericEventID = EventID.replace('event-', '');

  // Define state variables to hold the selected data
  const [selectedCompetition, setSelectedCompetition] = useState('');
  const [selectedClassificationID, setSelectedClassificationID] = useState('');
  const [selectedEventID, setSelectedEventID] = useState(numericEventID); // Use the extracted numeric ID
  const [selectedCompetitionID, setSelectedCompetitionID] = useState('');
  const [selectedGroupID, setSelectedGroupID] = useState('');
  const [selectedStageID, setSelectedStageID] = useState('');
  const [selectedStageIDStatus, setSelectedStageIDStatus] = useState('');
  const [stageStatusDesc, setStageStatusDesc] = useState('');
  const [stageIsTested, setStageIsTested] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [selectedGroupList, setselectedGroupList] = useState(''); 

  // Handler function to update the selected data
  const handleSelectedData = (
    competition,
    eventID,
    competitionID,
    groupID,
    stageID,
    stageIDStatus,
    stageStatusDesc,
    classificationID,
    stageIsTested,
    groupList
  ) => {
    setSelectedCompetition(competition);
    setSelectedEventID(eventID);
    setSelectedCompetitionID(competitionID);
    setSelectedGroupID(groupID);
    setSelectedStageID(stageID);
    setSelectedStageIDStatus(stageIDStatus);
    setStageStatusDesc(stageStatusDesc);
    setSelectedClassificationID(classificationID);
    setStageIsTested(stageIsTested);
    setselectedGroupList(groupList);
  };

  useEffect(() => {
    // Simulate data fetching delay
    const fetchData = async () => {


      // Replace this with actual data fetching logic
      await new Promise(resolve => setTimeout(resolve, 1000));
      setIsLoading(false); // Set loading state to false after data fetching
    };

    fetchData();
  }, []); // Run only once on component mount

  return (
    <div className="container-bkg">
      <Navbar EventID={numericEventID} />
    <div className="container is-fluid mt-1">
       
      <div className="mt-3">
        {/* Pass the handler function and competitionId to CompetitionInfoCard */}
        <CompetitionInfoCard
          onNavigateToResult={handleSelectedData}
          EventID={numericEventID} // Pass competitionId to CompetitionInfoCard
        />
      </div>

      {isLoading ? (
  <div>Loading...</div> // It's usually good to provide some loading indicator to the user
) : isMobile ? (
  stageIsTested ? (
    <TestLeaderboard
      // Pass the necessary props to the Leaderboard
      selectedEventID={selectedEventID}
      selectedCompetitionID={selectedCompetitionID}
      selectedGroupID={selectedGroupID}
      selectedStageID={selectedStageID}
      selectedClassificationID={selectedClassificationID}
      selectedStageIDStatus={selectedStageIDStatus}
      stageStatusDesc={stageStatusDesc} 
    />
  ) : (
    <Leaderboard
      // Pass the necessary props to the Leaderboard
      selectedEventID={selectedEventID}
      selectedCompetitionID={selectedCompetitionID}
      selectedGroupID={selectedGroupID}
      selectedStageID={selectedStageID}
      selectedClassificationID={selectedClassificationID}
      selectedStageIDStatus={selectedStageIDStatus}
      stageStatusDesc={stageStatusDesc} 
    />
    
  )
) : (
  <>
    <ResultTable
      selectedCompetition={selectedCompetition}
      selectedEventID={selectedEventID}
      selectedCompetitionID={selectedCompetitionID}
      selectedGroupID={selectedGroupID}
      selectedStageID={selectedStageID}
      selectedStageIDStatus={selectedStageIDStatus}
      selectedClassificationID={selectedClassificationID}
      stageStatusDesc={stageStatusDesc} 
      stageIsTested={stageIsTested}
      selectedGroupList={selectedGroupList}
    />
   { }
  </>
)}

    </div><div className="section">
      <FooterPart />
</div></div>
  );
};

export default Results;
