import React, { useState, useEffect } from 'react';
import CompetitionInfoCard from './CompetitionInfoCard';
import { useParams } from 'react-router-dom'; // Import useParams
import PenaltiesTable from './PenaltiesTable';
import FooterPart from './Footer';
const PagePenalties = () => {
  const { eventID } = useParams(); // Access the eventID from the URL params
  const numericEventID = eventID.startsWith('event-') ? eventID.replace('event-', '') : eventID;
    // Define state variables to hold the selected data
    const [selectedCompetition, setSelectedCompetition] = useState('');
    const [selectedClassificationID, setSelectedClassificationID] = useState('');
    const [selectedEventID, setSelectedEventID] = useState(numericEventID); // Use the extracted numeric ID
    const [selectedCompetitionID, setSelectedCompetitionID] = useState('');
    const [selectedGroupID, setSelectedGroupID] = useState('');
    const [selectedStageID, setSelectedStageID] = useState('');
    const [selectedStageIDStatus, setSelectedStageIDStatus] = useState('');
    const [StageStatusDesc, setStageStatusDesc] = useState('');

  // Extract just the numeric part if eventID starts with "event-"
  // List of colors

  

  const handleSelectedData = (
    competition,
    eventID,
    competitionID,
    groupID,
    StageID,
    stageIDStatus,
    stageStatusDesc,
    classificationID
  ) => {
    setSelectedCompetition(competition);
    setSelectedEventID(eventID);
    setSelectedCompetitionID(competitionID);
    setSelectedGroupID(groupID);
    setSelectedStageID(StageID);
    setSelectedStageIDStatus(stageIDStatus);
    setStageStatusDesc(stageStatusDesc);
    setSelectedClassificationID(classificationID);
  };


  return (
    <div className="container-bkg">
        <div className="container is-fluid mt-1">
    <div className="mt-3">
      <CompetitionInfoCard 
          onNavigateToResult={handleSelectedData}
          EventID={numericEventID} // Pass competitionId to CompetitionInfoCard
        />
     
      <div className="columns is-mobile is-centered">
      <PenaltiesTable
        selectedCompetition={selectedCompetition}
        selectedEventID={selectedEventID}
        selectedCompetitionID={selectedCompetitionID}
        selectedGroupID={selectedGroupID}
        selectedStageID={selectedStageID}
        selectedStageIDStatus={selectedStageIDStatus}
        selectedClassificationID={selectedClassificationID}
        stageStatusDesc={StageStatusDesc} // Lowercase "s" in stageStatusDesc
      />
</div>
    </div></div><div className="section">
      <FooterPart />
</div></div>
  );
}

export default PagePenalties;
