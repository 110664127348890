import React, { useState, useEffect } from 'react';






const FooterPart  = () => {
  return (
      <footer className="">
          <div className="has-text-centered">
              <p>
                  <strong>SOFTBERRY Dawid Janus</strong> © 2024 v0.2 <br/>
                    Wersja testowa. Mogą pojawić się błedy.
              </p>
          </div>
      </footer>
  );
};
export default FooterPart;
