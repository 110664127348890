import React, { useState, useEffect } from 'react';
import LeaderboardItem from './LeaderboardItem'; 
import MobileResultAfterStage from './MobileResultAfterStage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

// List of colors
const guestTextColors = [
  '#63AA57', '#6EB9E3', '#758AF8', '#8DEACE',
  '#B6F875', '#A2DDEA', '#9988FF', '#BECEF6', '#BCEAA0',
  '#ECA3A3', '#F5FF88', '#F875D3', '#FFBEA2', '#FFE5A2'
];

// Function to create a color mapping for InGroup values
const createColorMapping = (data) => {
  const colorMapping = {};
  let colorIndex = 0;
  data.forEach(item => {
    const inGroup = item.InGroup.trim();
    if (!colorMapping[inGroup]) {
      colorMapping[inGroup] = guestTextColors[colorIndex % guestTextColors.length];
      colorIndex++;
    }
  });
  return colorMapping;
};

const Leaderboard = ({
  selectedStageID,
  selectedClassificationID,
  selectedGroupID,
  selectedStageIDStatus,
  StageStatusDesc
}) => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    // Fetch leaderboard data 
    const apiUrl = 'https://www.admin.racepulse.pl/api/resultsGetStageResults';
    const fetchLeaderboardData = async () => {
      setIsLoading(true);
      setError(null);
      console.log('send',selectedGroupID,selectedClassificationID,selectedStageID)
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'UYwkNH50yAFoHI1mR62E'
          },
          body: JSON.stringify({
            StageID: selectedStageID,
            ClassificationID: selectedClassificationID,
            GroupListID: selectedGroupID.join(',')
          }),
        });

        if (response.status !== 200) {
          throw new Error('Brak Wyników');
        }

        const data = await response.json();
        setLeaderboardData(data.map(item => ({
          ...item,
          Car: item.Car.replace(/\n/g, ' '),
        })));
      } catch (error) {
        setError(error.toString());
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeaderboardData();

    // Handle scroll event
    const toggleScrollButton = () => {
      if (window.pageYOffset > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', toggleScrollButton);

    return () => window.removeEventListener('scroll', toggleScrollButton);
  }, [selectedStageID, selectedClassificationID, selectedGroupID]);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Create color mapping based on leaderboard data
  const colorMapping = createColorMapping(leaderboardData);

  if (isLoading) {
    return <div className="loading-spinner"></div>;
  }

  if (error) {
    return (
      <div>
        <div className="notification has-text-centered mt-1">
          <strong> Brak wyników</strong>
        </div>
      </div>
    );
  }

  return (
    <div>
      {selectedStageIDStatus ? (
        <div className="section">
          <div className="notification has-text-centered mt-1">
            <p>Odcinek odwołany {StageStatusDesc}</p>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="m-4">
            <div style={{ color: '#141414', fontSize: 24, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Odcinkowa</div>
          </div>
          <div className="columns result-table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
            <div>
              {leaderboardData.map((data, index) => (
                <LeaderboardItem key={index} data={data} colorMapping={colorMapping} />
              ))}
            </div>
          </div>
          <MobileResultAfterStage 
            selectedGroupID={selectedGroupID}
            selectedStageID={selectedStageID}
            selectedClassificationID={selectedClassificationID}
          />
          {showScrollButton && (
            <button onClick={scrollToTop} className="scroll-to-top-btn">
              <FontAwesomeIcon icon={faArrowUp} size="2x" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
