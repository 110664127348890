// Leaderboard.js
import React, { useState, useEffect } from 'react';
import LeaderboardItemAfterStage from './LeaderboardItemAfterStage'; // A component to render each leaderboard item
// List of colors
const guestTextColors = [
  '#63AA57', '#6EB9E3', '#758AF8', '#8DEACE',
  '#B6F875', '#A2DDEA', '#9988FF', '#BECEF6', '#BCEAA0',
  '#ECA3A3', '#F5FF88', '#F875D3', '#FFBEA2', '#FFE5A2'
];

// Function to create a color mapping for InGroup values
const createColorMapping = (data) => {
  const colorMapping = {};
  let colorIndex = 0;
  data.forEach(item => {
    const inGroup = item.InGroup.trim();
    if (!colorMapping[inGroup]) {
      colorMapping[inGroup] = guestTextColors[colorIndex % guestTextColors.length];
      colorIndex++;
    }
  });
  return colorMapping;
};

const MobileResultAfterStage = ({
  selectedStageID,
  selectedClassificationID,
  selectedGroupID
}) => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const apiUrl = 'https://www.admin.racepulse.pl/api/resultsGetAfterStageResults';
    const fetchLeaderboardData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'UYwkNH50yAFoHI1mR62E'
            
          },
          body: JSON.stringify({
            StageID: selectedStageID,
            ClassificationID: selectedClassificationID,
            GroupListID: selectedGroupID.join(',')
          }),
        });

        if (response.status !== 200) {
          throw new Error('Brak Wyników'); // "No results" in Polish
        }

        const data = await response.json();
        setLeaderboardData(data.map(item => ({
          ...item,
          Car: item.Car.replace(/\n/g, ' '),
          // Adjust other fields if necessary
        })));
      } catch (error) {
        setError(error.toString());
      } finally {
        setIsLoading(false);
      }
    };

    fetchLeaderboardData();
  }, [selectedStageID, selectedClassificationID, selectedGroupID]);

  const colorMapping = createColorMapping(leaderboardData);

  if (isLoading) {
    return <div className="loading-spinner"></div>
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
  
    
    <div className="">
     <div className="m-4">
            <div style={{ color: '#141414', fontSize: 24, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word' }}>Po odcinku</div>
          </div>
      <div className=" columns result-table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
      <div>
        {leaderboardData.map((data, index) => (
          <LeaderboardItemAfterStage key={index} data={data} colorMapping={colorMapping} />
        ))}
      </div>
    </div> </div>
  );
};

export default MobileResultAfterStage;
