import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({ options = [],value, onSelectionChange }) => {
  const [selectedOption, setSelectedOption] = useState(options.length > 0 ? options[0].value : '');
  const previousOptionsRef = useRef(options); // Track previous options
  
  useEffect(() => {
    // Compare old and new options
    const previousOptions = previousOptionsRef.current;
    const optionsChanged = JSON.stringify(previousOptions) !== JSON.stringify(options);
console.log('sel',selectedOption)
    if (optionsChanged) {
      setSelectedOption(options.length > 0 ? options[0].value : '');
      onSelectionChange([options.length > 0 ? options[0].value : '']);
    }

    previousOptionsRef.current = options; // Update the previous options
  }, [options, onSelectionChange]);
  useEffect(() => {
    // Update selected option when value prop changes
    if (value !== selectedOption) {
      setSelectedOption(value);
    }
  }, [value]);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    onSelectionChange([newValue]);
  };

  return (
    <div className="custom-class-selection">
      <select
        value={selectedOption}
        onChange={handleSelectChange}
        className="custom-select"
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
