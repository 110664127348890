import React from 'react';
import './mobilestyle.css';

const formatTime = (timeString) => {
  if (timeString === null) {
    return '--';
  }
    const [hours, minutes, secondsAndMillis] = timeString.split(':');
    const [seconds, milliseconds] = secondsAndMillis.split('.');
  
    let formattedTime = '';
  
    if (parseInt(hours, 10) !== 0) {
      formattedTime += `${parseInt(hours, 10)}:`;
    }
  
    formattedTime += `${parseInt(minutes, 10)}:${parseInt(seconds, 10)}.${milliseconds}`;
  
    return '+' + formattedTime.replace(/^0:/, ''); // Remove leading zero in hours
  };


const LeaderboardItemAfterStage = ({ data, colorMapping }) => {
   const guestTextColor = colorMapping[data.InGroup.trim()];
  return (
    <div className="mobile-container">
      <div className="inner-container">
        <div className="inner-box">
          <div className="number-box">{data.RowNumber}</div>
        </div>
        <div className="badge">
          <div className="badge-text">{data.PositionNumber}</div>
        </div>
      </div>
      <div className="m-content">
        <div className="text-content">
          <div className="names">{data.DriverName.trim()}<br/>{data.CODriverName.trim()}</div>
          <div className="guest-info">
            <div className="guest-badge" style={{ background: guestTextColor }}>
              <div className="guest-text">{data.InGroup.trim()}</div>
            </div>
            <div className="car-info">{data.Car.trim()}</div>
          </div>
        </div>
        <div className="timing">
          <div className="time">{data.ResultAndPenalty}</div>
          {data.Penalty && <div className="penalty">
            <div className="penalty-text">{`+${data.Penalty}`}</div>
          </div>}
          <div className="additional-times">
            <div className="additional-time">{formatTime(data.DiffToPrev)}</div>
            <div className="additional-time">{formatTime(data.TimeDifferenceToFirst)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardItemAfterStage;
